import './Servicios.css';
import imgArrow from './arrow.png'
import imgBurguer from './burguer.png';
import imgGlass from './glass.png';
import imgMusic from './music.png';
import imgOpen from './open.png';
import imgBeers from './image-beers-640.jpg';
import imgMicro from './image-micro-960.jpg';
import TextBlock from "../TextBlock/TextBlock";
import showCartaDueDate from "../../functions/ShowCartaDueDate";

const Icon = ({image, imageAlt, title, description}) => {
    return (
        <li className={"icon"}>
            <div className={"image"}>
                <img src={image} alt={imageAlt}/>
            </div>
            <p className={"title"}>{title}</p>
            <p className={"description"}>{description}</p>
        </li>
    )
};

const Servicios = () => {
    return (
        <div id={"servicios"} className={"servicios"}>
            <TextBlock
                title={"Qué te ofrecemos"}
                content={
                    <>
                        <p>
                            Karaoke, fiestas temáticas y de ambientación, DJ, bingo nocturno, conciertos ¡y mucho más!
                            Además, también disponemos de una gran variedad de servicios para que tu experiencia sea
                            extraordinaria. <br/><br/>

                            Si quieres pasar un rato divertido con amigos o familia, tenemos una zona de ocio y juegos
                            con billar, futbolín y videojuegos. También tenemos un <strong>karaoke</strong> para cantar
                            y bailar hasta el amanecer. Para velada romántica o simplemente relajarte, tenemos un
                            ambiente nocturno acogedor y tranquilo... Y si simplemente te apetece disfrutar de un rato
                            agradable entre amigos al aire libre, tenemos una terraza exterior con unas vistas al mar
                            impresionantes. <br/><br/>

                            Además, ofrecemos parking gratuito justo al lado del local para que no tengas que
                            preocuparte por dónde dejar tu coche. Por si fuera poco, en toda la temporada estival
                            servimos una comida deliciosa.
                        </p>
                    </>
                }
                contentAligment={"center"}
            />
            <ul className={"icons"}>
                <Icon
                    image={imgMusic}
                    imageAlt={"Karaoke, bailes y fiestas"}
                    title={"Animación nocturna"}
                    description={"Karaoke, conciertos, bingo,  DJ, fiestas temáticas, etc!"}
                />

                <Icon
                    image={imgOpen}
                    imageAlt={"Zona de juegos"}
                    title={"Zona de juegos"}
                    description={"Pinball, billar, futbolín, arcades y más!"}
                />

                <Icon
                    image={imgGlass}
                    imageAlt={"Terraza exterior"}
                    title={"Terraza exterior"}
                    description={"Amplia terraza exterior con vistas a la ría"}
                />

                {
                    showCartaDueDate() &&
                    <Icon
                        image={imgBurguer}
                        imageAlt={"Desayunos, comidas y cenas"}
                        title={"Buena comida"}
                        description={"Platos combinados, hamburguesas, bocadillos, ensaladas..."}
                    />
                }

                <Icon
                    image={imgArrow}
                    imageAlt={"Parking"}
                    title={"Parking"}
                    description={"Aparcamiento para clientes al lado del local"}
                />
            </ul>
            <div className={"disfrute"}>
                <div className={"image"}>
                    <img src={imgBeers} alt="Ven a brindar con nosotros"/>
                </div>
                <TextBlock
                    title={"Un lugar para disfrutar"}
                    content={
                        <>
                            <p>
                                Ya sea que quieras disfrutar de una merienda al sol y junto al mar o simplemente
                                pasar un buen rato con amigos, Sunbar Snack es el lugar. ¡Somos el punto de encuentro de
                                locales y viajeros, y estamos listos para hacerte sentir como en casa!
                            </p>
                        </>
                    }
                    contentAligment={"center"}
                />
            </div>
            <div className={"image-micro"}>
                <img src={imgMicro} alt="Ven a disfrutar de nuestras noches de Karaoke!"/>
            </div>
        </div>
    )
}


export default Servicios;