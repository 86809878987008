import './Contacto.css';
import "react-toastify/dist/ReactToastify.css";
import TextBlock from "../TextBlock/TextBlock";
import Button from "../Button/Button";
import {ToastContainer, toast} from 'react-toastify';
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faLocationDot, faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";


const Contacto = () => {
    const [sending, setSending] = useState(false);

    /**
     * https://fkhadra.github.io/react-toastify/
     * @param {string} type
     * @param {string} message
     */
    const ShowToast = (type, message) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    const SendForm = async () => {
        const form = document.forms[0];

        if (!form.reportValidity()) return;

        setSending(true);

        const SendIt = async (token) => {
            const name = document.querySelector('#name');
            const email = document.querySelector('#email');
            const message = document.querySelector('#message');

            const data = new FormData();
            data.append('name', name.value);
            data.append('email', email.value);
            data.append('message', message.value);
            data.append('g-recaptcha-response', token);

            try {
                const req = await fetch('/php/contact_c.php', {
                    method: 'post',
                    body: data
                });

                const res = await req.json();

                if (res.success) {
                    ShowToast('success', '¡Mensaje enviado correctamente!');
                    name.value = '';
                    email.value = '';
                    message.value = '';
                }
                else {
                    ShowToast('error', res.message);
                }
            }
            catch (e) {
                ShowToast('error', 'Ha ocurrido un error, ¡inténtalo de nuevo!')
            }
            finally {
                setSending(false);
                window.grecaptcha.reset();
                document.querySelector('.grecaptcha-badge').style.display = 'none';
            }
        }

        try {
            window.grecaptcha.render(document.querySelector('#g-recaptcha'), {
                sitekey: '6LcYmj4pAAAAAPfnfr_zhA8EqVVzEE-2KTjtApS_',
                size: 'invisible',
                callback: token => SendIt(token)
            });
        }
        catch (e) {
            try {
                document.querySelector('.grecaptcha-badge').style.display = 'inherit';
            }
            catch (e) {
            }
        }

        window.grecaptcha.execute();
    };

    const TriggerDisclaimer = () => {
        document.querySelector('.show-disclaimer').click();
    };

    const TriggerPdp = () => {
        document.querySelector('.show-pdp').click();
    };

    return (
        <div id={"contacto"} className={"contacto"}>
            <TextBlock
                title={"Ven a visitarnos"}
                content={
                    <>
                        <p>¡No esperes más! Ven a visitarnos ¡y únete a la diversión! Convierte Sunbar Snack en tu
                            lugar de encuentro.</p>
                        <address><a href="https://maps.app.goo.gl/EPkaqb8N7U4pLJtVA" rel={"noreferrer"}
                                    target={"_blank"}>Sunbar Snack. EP-9101, 13, 36988 O Grove, Pontevedra</a></address>
                    </>
                }
                contentAligment={"center"}
            />
            <div className={"map"}>
                <iframe
                    title={"Localización de Sunbar Snack en el mapa"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2943.0897477431654!2d-8.927569322468969!3d42.46837987118307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2f46d2b7a900a7%3A0x716ee9124e83efa!2sSunbar%20Snack!5e0!3m2!1ses-419!2ses!4v1701759495853!5m2!1ses-419!2ses"
                    width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className={"form"}>
                <TextBlock
                    title={"Contacta con nosotros"}
                    content={
                        <>
                            <p>Si quieres más información sobre los servicios que ofrecemos, las
                                instalaciones, el entorno, actividades o cualquier otra duda, puedes ponerte en contacto
                                a través de los siguientes medios, ¡estaremos encantados de ayudarte!</p>
                        </>
                    }
                    contentAligment={"center"}
                />
                <div className={"contact-methods"}>
                    <div className="row">
                        <p>Dirección</p>
                        <address>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            <a href="https://maps.app.goo.gl/EPkaqb8N7U4pLJtVA" rel={"noreferrer"}
                               target={"_blank"}>Sunbar Snack. Area, 2, 36988 San Vicente, O Grove (Pontevedra)</a>
                        </address>
                    </div>
                    <div className="row">
                        <p>Teléfono</p>
                        <p>
                            <FontAwesomeIcon icon={faPhone}/>
                            <a href="tel:+34679856923" title="Llamar">(+34) 679 85 69 23</a>
                        </p>
                    </div>
                    <div className="row">
                        <p>E-mail</p>
                        <p>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <a title={"Toca para abrir tu app de mensajería"}
                               href="mailto:info@sunbarsnack.es">info@sunbarsnack.es</a>
                        </p>
                    </div>
                    <div className="row">
                        <p>Instagram</p>
                        <p>
                            <FontAwesomeIcon icon={faInstagram}/>
                            <a className="external-link" href="https://www.instagram.com/direct/t/17846485371089928"
                               target="_blank" rel="nofollow noopener noreferrer">Escríbenos a través de Instagram <i
                                className="fab fa-facebook-messenger"></i></a>
                        </p>
                    </div>
                    <div className="row">
                        <p>Facebook</p>
                        <p>
                            <FontAwesomeIcon icon={faFacebook}/>
                            <a className="external-link" href="https://www.facebook.com/messages/t/214706115056134/"
                               target="_blank" rel="nofollow noopener noreferrer">Escríbenos a través de Facebook <i
                                className="fab fa-facebook-messenger"></i></a>
                        </p>
                    </div>
                </div>
                <form>
                    <p className={"title"}>Formulario de contacto</p>

                    <label><input type="text" placeholder={"Nombre *"} id={"name"} autoComplete={"name"}
                                  required/></label>
                    <label><input type="email" placeholder={"Email *"} id={"email"} autoComplete={"email"}
                                  required/></label>
                    <label><textarea id={"message"} placeholder={"Mensaje *"} required/></label>

                    <Button title={"Enviar formulario de contacto"} className={"send-form"} onClick={SendForm}
                            disabled={sending}>
                        Enviar formulario {sending && <FontAwesomeIcon icon={faSpinner} spin/>}
                    </Button>

                    <div id="g-recaptcha"></div>

                    <div className={"form-disclaimer"}>
                        <p>El <Button title={"Mostrar aviso legal"} className={"anchor trigger-disclaimer"}
                                      onClick={TriggerDisclaimer}>titular de la página</Button> informa que los datos
                            enviados a través de
                            este formulario serán
                            tratados únicamente para ofrecerle la información solicitada, siendo la base legal del
                            tratamiento el consentimiento otorgado por el usuario. No se cederán datos a terceros. Puede
                            ejercer sus derechos como se explica en la <Button
                                title={"Mostrar política de privacidad y cookies"} className={"anchor trigger-pdp"}
                                onClick={TriggerPdp}>Política de privacidad y
                                Cookies</Button>.</p>
                    </div>
                </form>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={15000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
};


export default Contacto;