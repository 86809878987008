import './Header.css';
import imageConcierto from './image-top-concierto-1920.jpg';
import TextBlock from "../TextBlock/TextBlock";

const Header = () => {
    return (
        <header>
            <div className={"full-image"}>
                <img src={imageConcierto} alt="Cócteles y bebidas deliciosas"/>
            </div>
            <TextBlock
                title={
                <div>
                    <p>ANIMACIÓN NOCTURNA</p>
                    <p>¡Esta noche hay FIESTA!</p>
                </div>}
                content={<p>Bienvenido a tu rincón de <strong>animación nocturna</strong> y <strong>karaoke</strong> en O Grove.
                    Aquí nos tomamos en serio la diversión y la satisfacción de nuestros clientes, por eso nuestro
                    local está preparado y dividido en zonas donde comer, donde jugar, bailar y cantar. <br/>
                    Únete a las fiestas temáticas y de ambientación, conciertos y mucho más que celebramos a lo largo
                    del año. ¡Ven a cantar y bailar con nosotros! <br/>
                    ¡Estamos listos para que vivas una experiencia que seguro recordarás!</p>}
                contentAligment={"center"}
            >
            </TextBlock>
        </header>
    )
}

export default Header;