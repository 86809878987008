import './Navbar.css';
import logoImage from './logo-sunbarsnack-480w.png';
import {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import Button from "../Button/Button";
import ShowCartaDueDate from "../../functions/ShowCartaDueDate";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";


const NAV_SECTIONS = {
    inicio: 'inicio',
    novedades: 'novedades',
    carta: 'carta',
    servicios: 'servicios',
    contacto: 'contacto'
}

const Navbar = () => {
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(NAV_SECTIONS.inicio);

    const ToggleMenu = useCallback(() => {
        if (!opened) {
            setOpened(true);
            window.history.pushState('menu', '');
        }
        else {
            setOpened(false);
        }
    }, [opened]);

    const ScrollToSection = async (ev) => {
        ToggleMenu();

        const el = ev.currentTarget;

        setSelected(NAV_SECTIONS[el.dataset.target]);

        await new Promise(r => setTimeout(r, 10));

        document.querySelector(`#${el.dataset.target}`).scrollIntoView();

        window.scrollTo(0, window.scrollY - 101);
    };

    const TriggerLaCarta = () => {
        document.querySelector('.modal-carta').click();
    }

    useEffect(() => {
        window.onpopstate = () => {
            if (opened) ToggleMenu();
        }
    }, [opened, ToggleMenu]);


    return (
        <nav className={opened ? 'opened' : null}>
            <div className={"logo"}>
                <img src={logoImage} alt="Logo Sunbar Snack"/>
            </div>
            {
                ShowCartaDueDate() &&
                <Button title={"Mostrar la carta"} className={"trigger-la-carta"} onClick={TriggerLaCarta}>ver carta</Button>
            }

            <div className={"nav-trigger"}>
                <div className={"bars"} onClick={ToggleMenu}>
                    <FontAwesomeIcon icon={faBars}/>
                </div>
                <div className={"times"} onClick={ToggleMenu}>
                    <FontAwesomeIcon icon={faTimes}/>
                </div>
            </div>
            <ul style={{backgroundImage: `url(${logoImage})`}}>
                <li onClick={ScrollToSection} data-target={"inicio"}
                    className={selected === 'inicio' ? 'selected' : null}>
                    <a href="#inicio">Inicio</a>
                </li>
                <li onClick={ScrollToSection} data-target={"novedades"}
                    className={selected === 'novedades' ? 'selected' : null}>
                    <a href="#novedades">Novedades</a>
                </li>
                {
                    ShowCartaDueDate() &&
                    <li onClick={ScrollToSection} data-target={"carta"}
                        className={selected === 'carta' ? 'selected' : null}>
                        <a href="#carta">Carta</a>
                    </li>
                }

                <li onClick={ScrollToSection} data-target={"servicios"}
                    className={selected === 'servicios' ? 'selected' : null}>
                    <a href="#servicios">Servicios</a>
                </li>
                <li onClick={ScrollToSection} data-target={"contacto"}
                    className={selected === 'contacto' ? 'selected' : null}>
                    <a href="#contacto">Contacto</a>
                </li>

                <li className={"rrss"}>
                    <p className={"title"}>¡No dejes de seguirnos!</p>
                    <div className={"uris"}>
                        <a href={"https://www.instagram.com/snacksunbar/"} target={"_blank"} rel={"noreferrer"}
                           className={"ig"} aria-label={"hiden"} title={"Síguenos en Instagram"}>
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a href={"https://www.facebook.com/profile.php?id=61555486260674"} target={"_blank"}
                           rel={"noreferrer"} className={"fb"} aria-label={"hidden"} title={"Síguenos en Facebook"}>
                            <FontAwesomeIcon icon={faFacebook}/>
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;