import './Footer.css';
import TextBlock from "../TextBlock/TextBlock";
import {useCallback, useEffect, useState} from "react";
import Disclaimer from "../Disclaimer/Disclaimer";
import Button from "../Button/Button";
import Pdp from "../Pdp/Pdp";

const Footer = () => {
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [showPdp, setShowPdp] = useState(false);

    const ShowDisclaimerModal = useCallback(() => {
        if (!showDisclaimer) {
            setShowDisclaimer(true);
            window.history.pushState('disclaimer', '');
        }
        else {
            setShowDisclaimer(false);
        }
    }, [showDisclaimer]);

    const ShowPdpModal = useCallback(() => {
        if (!showPdp) {
            setShowPdp(true);
            window.history.pushState('disclaimer', '');
        }
        else {
            setShowPdp(false);
        }
    }, [showPdp]);

    useEffect(() => {
        window.onpopstate = () => {
            if (showDisclaimer) ShowDisclaimerModal();
            if (showPdp) ShowPdpModal();
        }
    }, [showDisclaimer, ShowDisclaimerModal, showPdp, ShowPdpModal]);


    return (
        <>
            <footer>
                <div className={"info"}>
                    <TextBlock
                        title={"Sunbar Snack en O Grove"}
                        content={
                            <>
                                <address>Area, 2, 36988 San Vicente, O Grove (Pontevedra)</address>
                                <p>Teléfono: <a href="tel:+34679856923" title="Llamar">(+34) 679 85 69 23</a></p>
                                <p>E-mail: info@sunbarsnack.es</p>
                            </>
                        }
                        contentAligment={"center"}
                    />
                </div>
                <div className={"end"}>
                    <div className={"copyright"}>
                        <p>www.sunbarsnack.es © {new Date().getUTCFullYear()} - Todos los derechos reservados</p>
                    </div>
                    <div className={"footer-disclaimer"}>
                        <p>
                            <Button className={"anchor show-disclaimer"} title={"Mostrar aviso legal"} onClick={ShowDisclaimerModal}>Aviso legal</Button>
                            <Button className={"anchor show-pdp"} title={"Mostrar Política de privacidad"} onClick={ShowPdpModal}>Política de privacidad y cookies</Button>
                        </p>
                    </div>
                </div>
            </footer>
            {
                showDisclaimer && <Disclaimer close={() => ShowDisclaimerModal()}/>
            }
            {
                showPdp && <Pdp close={() => ShowPdpModal()}/>
            }
        </>

    )
};

export default Footer;