import './Button.css';

const Button = ({children, title = "", className = "", onClick = () => {}, disabled = false}) => {
    return (
        <button type={"button"} title={title} className={className} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    )
}


export default Button;