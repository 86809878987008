import './LaCarta.css';
import cartajson from './lacarta.json';
import pdf from './sunbar-snack-carta.pdf';
import pdfDesayunos from './sunbar-snack-carta-desayunos.pdf';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faFileArrowDown} from '@fortawesome/free-solid-svg-icons';

const LaCarta = ({close}) => {

    const CartaData = () => {
        return cartajson.map((row, i) => {
            return <div key={i} className={"category"}>
                <p className={"title"}>{row.Category}</p>
                <div className={"data"}>
                    {row.Items.map((item, i) => {
                        return <div key={i} className={"row"}>
                            <div className={"name"}>
                                <span className={"name"}>{item.item}</span>
                                {item.info && <span className={"info"}>{item.info}</span>}
                            </div>
                            <div className={"value"}>{item.price}</div>
                        </div>
                    })}
                </div>
            </div>
        })
    };

    window.eljson = cartajson;

    return (
        <div className={"la-carta"}>
            <div className={"header"}>
                <div className={"pdf-url"}>
                    <a target={"_blank"} rel={"noreferrer"} href={pdfDesayunos}>
                        <FontAwesomeIcon icon={faFileArrowDown}/>
                        <span>Desayunos</span>
                    </a>
                    <a target={"_blank"} rel={"noreferrer"} href={pdf}>
                        <FontAwesomeIcon icon={faFileArrowDown}/>
                        <span>Comidas y cenas</span>
                    </a>
                </div>
                <div className={"times"} onClick={close}>
                <FontAwesomeIcon icon={faTimes}/>
                </div>
            </div>

            <div className={"body"}>
                {CartaData()}
            </div>
        </div>
    )
};

export default LaCarta;