import './Carta.css';
import imageBurguers from './image-carta-burguers-960.jpg';
import TextBlock from "../TextBlock/TextBlock";
import Button from "../Button/Button";
import LaCarta from "../LaCarta/LaCarta";
import {useCallback, useEffect, useState} from "react";

const Carta = () => {
    const [showCartaModal, setShowCartaModal] = useState(false);

    const ShowCarta = useCallback(() => {
        if (!showCartaModal) {
            setShowCartaModal(true);
            window.history.pushState('la-carta', '');
        }
        else {
            setShowCartaModal(false);
        }
    }, [showCartaModal]);

    useEffect(() => {
        window.onpopstate = () => {
            if (showCartaModal) ShowCarta();
        }
    }, [showCartaModal, ShowCarta]);


    return (
        <>
            <div id={"carta"} className={"carta"} style={{backgroundImage: `url(${imageBurguers})`}}>
                <TextBlock
                    title={<p>
                        <span className={"color-white"}>Comida que te hace la boca agua</span>
                    </p>}
                    content={<>
                        <p>
                            En nuestro local encontrarás una amplia variedad de platos para todos los gustos. Si
                            buscas algo rápido y sabroso, nuestras hamburguesas y bocadillos son una opción ideal. Si
                            quieres algo más contundente, nuestros platos combinados están elaborados con ingredientes
                            frescos y de temporada.<br/><br/>
                            En todos nuestros platos, utilizamos productos de primera calidad y una elaboración cuidada.
                            El resultado es una comida deliciosa que te dejará satisfecho.
                        </p>
                        <br/><br/>
                        <Button title={"Mostrar la carta"} className={"modal-carta"} onClick={ShowCarta}>
                            <span>Toca para ver la carta →</span>
                        </Button>
                    </>}
                />
            </div>
            {
                showCartaModal && <LaCarta close={() => ShowCarta()}/>
            }
        </>
    )
}


export default Carta;