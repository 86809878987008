import './TextBlock.css';

const TextBlock = ({title, content, titleAligment="center", contentAligment="left"}) => {
    return (
        <div className={"text-block"}>
            <div className={`title ${titleAligment}`}>{title}</div>
            <div className={`content ${contentAligment}`}>{content}</div>
        </div>
    )
}

export default TextBlock;