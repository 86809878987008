import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import Carta from "./components/Carta/Carta";
import Servicios from "./components/Servicios/Servicios";
import Contacto from "./components/Contacto/Contacto";
import Footer from "./components/Footer/Footer";
import InstagramFeed from "./components/InstagramFeed/InstagramFeed";
import ShowCartaDueDate from "./functions/ShowCartaDueDate";
import Equalizer from "./components/Equalizer/Equalizer";


function App() {
    return (
        <div id={"inicio"} className="sunsnack-app">
            <Navbar/>
            <Header/>
            <InstagramFeed/>
            <main>
                {ShowCartaDueDate() && <Carta/>}
                <Servicios/>
                <Contacto/>
            </main>
            <Footer/>
            <Equalizer/>
        </div>
    );
}

export default App;