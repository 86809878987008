import './Pdp.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import TextBlock from "../TextBlock/TextBlock";

const Pdp = ({close}) => {
    return (
        <div className={"pdp"}>
            <div className={"header"}>
                <div className={"title"}>Política de privacidad y cookies</div>
                <div className={"times"} onClick={close}>
                    <FontAwesomeIcon icon={faTimes}/>
                </div>
            </div>
            <div className={"body"}>
                <div className={"title"}>Política de privacidad</div>
                <div className={"content"}>
                    <TextBlock
                        title={"1. Responsable del tratamiento"}
                        titleAligment={"left"}
                        content={
                            <p>El Responsable del Tratamiento se corresponde con el del titular de este sitio web, cuyos
                                datos completos figuran en el Aviso legal.</p>
                        }
                    />
                    <TextBlock
                        title={"2. Datos tratados, finalidades y plazos de conservación"}
                        titleAligment={"left"}
                        content={
                            <ul>
                                <li>2.1. Datos tratados: A los efectos de la solicitud de información, contacto, cita o
                                    la suscripción de nuestros boletines y newsletter, se deberán facilitar unos datos a
                                    través de la cumplimentación del formulario dispuesto a tal efecto, en el que se
                                    indicará cuáles de ellos tienen el carácter de obligatorio.
                                </li>
                                <li>Durante la navegación obtenemos datos que nos aportan información sobre usabilidad y
                                    preferencias de los usuarios, según los términos y condiciones que se prevén
                                    expresamente en el apartado de cookies.
                                </li>
                                <li>2.2. Finalidades: <ul>
                                    <li>1. Contacto: El correo electrónico o el teléfono facilitado para el contacto,
                                        serán utilizados para una efectiva contestación a las cuestiones planteadas.
                                    </li>
                                    <li>2. Boletines o newsletters: El correo electrónico, el cual deberá ser verificado
                                        por el usuario, u otros similares, como pueda ser el teléfono, serán utilizados
                                        para la remisión de los boletines o newsletters, de acuerdo a sus intereses.
                                    </li>
                                </ul></li>
                                <li>2.3. Plazos de conservación: <ul>
                                    <li>1. Contacto: Los datos serán borrados una vez se haya dado curso y respondido a
                                        la cuestión planteada por el usuario.
                                    </li>
                                    <li>2. Boletines o newsletters: Los datos serán tratados, desde que el usuario
                                        preste su consentimiento hasta la retirada del mismo, el cual podrá otorgarse
                                        cuantas veces quiera. En cada comunicación, el usuario encontrará un
                                        procedimiento gratuito y accesible para gestionarlo.
                                    </li>
                                </ul></li>
                            </ul>
                        }
                    />
                    <TextBlock
                        title={"3. Ejercicio de derechos"}
                        titleAligment={"left"}
                        content={
                            <>
                                <p>Cualquier persona tiene derecho a obtener confirmación acerca de si se está
                                    procediendo al tratamiento de datos personales que les conciernan, o no. Las
                                    personas interesadas tienen derecho a si acceder a sus datos personales, así como a
                                    solicitar la rectificación de los datos inexactos o, en su caso, solicitar su
                                    supresión cuando, entre otros motivos, los datos ya no sean necesarios para los
                                    fines que fueron recogidos.</p>
                                <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del
                                    tratamiento de sus datos u oponerse al tratamiento de los mismos, en cuyo caso
                                    únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>
                                <p>Para ello, los usuarios podrán dirigir una comunicación por escrito a la dirección
                                    fiscal o dirección de correo electrónico que figuran en el Aviso legal, incluyendo
                                    en ambos casos una fotocopia del documento nacional de identidad o documentación
                                    acreditativa equivalente.</p>
                                <p>En caso de que desea ejercer el derecho de portabilidad, se facilitará esos datos al
                                    nuevo Responsable.</p>
                            </>
                        }
                    />
                    <TextBlock
                        title={"4. Legitimación del tratamiento"}
                        titleAligment={"left"}
                        content={
                            <p>La base legal para el tratamiento de los datos facilitados será el consentimiento
                                otorgado por el usuario mediante la cumplimentación del formulario y; (ii) la
                                satisfacción de los intereses legítimos del Responsable del Tratamiento.</p>
                        }
                    />
                    <TextBlock
                        title={"5. Destinatarios y cesiones"}
                        titleAligment={"left"}
                        content={
                            <p>No se realizarán cesiones de datos ni transferencias internacionales de ningún tipo.</p>
                        }
                    />
                </div>
                <hr/>
                <div className={"title"}>Política de cookies</div>
                <div className={"content"}>
                    <TextBlock
                        title={""}
                        content={
                            <>
                                <p>Las cookies son pequeños archivos de texto generados por las páginas web que visitas,
                                    las cuales contienen los datos de sesión que pueden ser de utilidad más adelante en
                                    la página web. Los datos permiten que la página web pueda mantener su información
                                    entre las páginas, y también para analizar la forma de interactuar con el sitio.</p>
                                <p>Las cookies son seguras ya que sólo pueden almacenar la información que se puso en su
                                    lugar por el navegador, lo que es información que el usuario ha introducido en el
                                    navegador o la que se incluye en la solicitud de página. No pueden ejecutar código y
                                    no se pueden utilizar para acceder a tu ordenador.</p> <p>Las cookies son esenciales
                                para el funcionamiento de internet, aportando innumerables ventajas en la prestación de
                                servicios interactivos, facilitándole la navegación y usabilidad de la web. Las cookies
                                no pueden dañar su equipo, no pueden ejecutar código y no se pueden utilizar para
                                acceder a tu ordenador, y que estén activadas ayuda a identificar y resolver los
                                errores.</p> <p>Algunos servicios pueden utilizar conectores a redes sociales (plugins
                                sociales) tales como Facebook, Twitter, Google +, etc. En este caso, debes consultar la
                                política de cookie de estas redes sociales, toda vez que al utilizar el registro social
                                estás autorizando a la red social a almacenar cookies.</p> <p>Puede gestionar las
                                cookies de este sitio web mediante el enlace que aparece en la parte superior de cada
                                página.</p> <p>Todos los navegadores de Internet permiten limitar el comportamiento de
                                una cookie o desactivar las cookies dentro de la configuración o las opciones del
                                navegador. Los pasos para hacerlo son diferentes para cada navegador, pudiéndose
                                encontrar instrucciones en el menú de ayuda de tu navegador.</p>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Pdp;