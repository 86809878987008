const ShowCartaDueDate = () => {
    const date = new Date();
    const month = date.getMonth();
    const day = date.getDate();
    if (month >= 5 && month < 10) {
        if (month === 5 && day < 15) return false;
        if (month === 10 && day > 15) return false;
        return true;
    }
    return false;
};

export default ShowCartaDueDate;