import cartel from "./cartel.jpg";
import './Equalizer.css';
import {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const Equalizer = () => {
    const [opened, setOpened] = useState(false);

    const ToggleKaraoke = useCallback(() => {
        if (!opened) {
            setOpened(true);
            window.history.pushState('karaoke-modal', '');
        }
        else {
            setOpened(false);
        }
    }, [opened]);

    useEffect(() => {
        window.onpopstate = () => {
            if (opened) ToggleKaraoke();
        }
    }, [opened, ToggleKaraoke]);

    return (
        <>
            <div className={"equalizer"} onClick={ToggleKaraoke}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            {
                opened &&
                <div className={"karaoke-cartel"}>
                    <div className={"header"}>
                        <div className={"title"}>¡No te lo pierdas!</div>
                        <div className={"times"} onClick={ToggleKaraoke}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    </div>

                    <div className={"body"}>
                        <img src={cartel} alt="Cartel Karaoke Party"/>
                    </div>
                </div>
            }
        </>
    )

};

export default Equalizer;